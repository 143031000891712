// Variables

$welcome-font-18: 18px;
$welcome-font-14: 14px;

.welcome-top{
    height: 70%;
    background-color: #ffe8d1;
    position: relative;
}
.welcome-order{
    width: 40%;
}

.welcome-company-name {
    font-size: 40px;
    font-weight: 700;
}

.welcome-company-address {
    font-size: $welcome-font-14;
    padding-bottom: 20px;
}

.welcome-company-img{
    height: 470px;
    width: 100%;
    background-position: center;
    margin-left: 5%;
}

.welcome-booking-btn{
    background-color: #36d3d3;
    padding: 10px;
    color: white;
    border-radius: 20px;
    font-size: $welcome-font-18;
    text-align: center;
    width: 100%;
    max-width: 300px;
}
.welcome-img{
    width: 75%;
    height: 600px;
    // background-size: contain;
}
.welcome-about{
    background-color: #ffecda;
    justify-content: space-around;
}
.welcome-about-us{
    font-size: $welcome-font-18;
    display: inline-block;
    position: relative;
}
.welcome-about-us-content{
    font-size: $welcome-font-14;
    color: #222222;
}

.welcome-shape{
    height: 1px;
    margin: 0 auto 20px;
    position: relative;
    width: 60px;
    background-color: #36d3d3;
}

.welcome-about-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.welcome-contact{
    display: flex;
    justify-content: space-around;
    padding: 45px;
    width: 85%;
    margin: 0 auto;
}
#schedule, #contact {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    min-height: inherit;
}
.section-pd {
    padding: 15px 40px;
}
#contacts ul{
    padding: 0;
}
#contacts li {
    margin-bottom: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
}

#contacts li .icon {
    font-size: 16px;
    float: left;
    width: 15px;
}
#contacts li .links {
    font-size: 16px;
    padding-left: 10px;
    overflow: hidden;
}

@include media-breakpoint-down(xl) {
    .welcome-order{
        width: 100%;
    }
    .welcome-img{
        width: 100%; 
        height: 500px;
        background-size: cover;
        background-position: center;
    }
    .welcome-contact{
        display: block;
    }
    .welcome-company-img{
        height: 400px;
        width: 100%;
        margin-left: 25px;
    }
}
  
 .welcome .wave{
    position: absolute;
    left: 0;
    bottom: -65px;
    width: 100%;
 }

 .welcome .box {
    // 46, 38, 52
    --size: 42px;
    --p: 42px;
    --R: 59.9px /*sqrt(var(--size)*var(--size) + var(--p)*var(--p))*/;
    
    height: 72px;
    // margin: 10px 0;
    
    -webkit-mask:
      radial-gradient(var(--R) at left 50% var(--d,top) calc(var(--size) + var(--p)),#000 99%,#0000 101%) 
        calc(50% - 2*var(--size)) 0/calc(4*var(--size)) 100%,
      radial-gradient(var(--R) at left 50% var(--d,top) calc(-1*var(--p)),#0000 99%,#000 101%) 
        left 50% var(--d,top) var(--size)/calc(4*var(--size)) 100% repeat-x;
        // fd8e8c
        // fdd7b5
    background:linear-gradient(90deg, #fdd7b5, #ffe8d1);  
    border: none;
  }
  
  .welcome .box + .box {
    --d: bottom;
  }

@include media-breakpoint-down(md) {
    .welcome-img{
        height: 400px;
        background-size: cover;
        background-position: center;
    }
    .welcome-booking-btn{
        max-width: 100%;
    }
    .box {
        --size: 42px;
        --p: 42px;
        --R: 50px /*sqrt(var(--size)*var(--size) + var(--p)*var(--p))*/;
        
        height: 62px;
    }
    .welcome-company-img{
        height: 290px;
        background-position: center;
        margin-left: 10%;
        width: 75%;
    }
}

.custom-menu-hover{
    &:hover{
        .custom-menu-text{
            color: var(--kt-text-primary) !important;
        }
    }   
}
 
